:root {
  // COLORING
  --color-primary: rgb(21, 73, 156);
  --color-primary-light: #3d92ff;

  --color-primary-light-1: #71cbea;
  --color-primary-light-2: #00c9c7;

  --color-secondary: rgb(255, 0, 127);
  --color-grey-1: #eee;
  --color-grey-2: #999;
  --color-grey-3: #666;
  --color-grey-4: #333;

  --color-white: #fff;
  --color-black: #000;

  --color-text: #445064;

  // FONT-SIZEING
  --font-sm: 1.2rem;
  --font-md: 1.4rem;
  --font-lg: 1.8rem;
  --font-xl: 2.2rem;
  --font-xxl: 4rem;

  // SCREEN SIZEING
  --max-width: 428px;

  // PAGE BREAK
  --mobile: 430px;
  --tablet: 600px;
  --screen: 1200px;
  --lg-screen: 1600px;
}
