*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
h1,
h2,
h3,
h4,
label,
p {
  margin-bottom: 0;
}
body {
  // font-size: 62.5%;
  font-family: 'Prompt', sans-serif;
  // font-weight: 400;
  height: 100%;
  div#root {
    height: 100%;
  }
}

// override image uploader widget
.ant-upload-select-picture-card {
  width: 100% !important;
  height: 15rem !important;
  margin: 0;
  padding: 0;
  img {
    object-fit: contain;
    height: 100%;
  }
}

// remove margin from image uploader in collapsebox
.ant-collapse-content-box > .ant-form-item {
  margin: 0;
}

// override collapse header color
.ant-collapse-header {
  background: #fff;
}
// // override
.ant-checkbox-group {
  padding: 0 1rem;
}
.ant-checkbox-wrapper {
  gap: 1.2rem;
}

// MODIFY to antd
.ant-radio-group-solid {
  width: 100%;
}
.ant-radio-button-wrapper {
  padding: 0;
  text-align: center;
  width: 50%;
}

// MODIFY SOCIAL LOGIN BUTTON
// REMOVE ICON FROM NORMAL LOGIN
.styled-login-button > div > div {
  i {
    display: none;
  }
  &:nth-child(2) {
    width: 0 !important;
  }
}

// MODAL HEIGHT SCROLL
.ant-modal-body {
  max-height: 75vh;
  // border-radius: 40px;
  // overflow-y: scroll;
}

//
.auth-page {
  @media only screen and (min-width: 500px) {
    width: clamp(500px, 500px, 80vw);
    margin: auto;
    // margin-top: 4rem;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.1);
    padding: 1rem;
    border-radius: 1rem;
    background: white;

    > div:nth-child(1) {
      background: none;
    }
  }
}

.app-content {
  padding: 1rem 0;
  width: 100%;

  &__inner {
    @media only screen and (min-width: 500px) {
      height: 80vh;
      display: flex;
      width: clamp(800px, 80vw, 1000px);
      margin: 0 auto;
      & > .step-content {
        // flex: 1;
        max-width: 400px;
        min-width: 400px;
        margin-left: auto;
        // display: flex;
        // flex-direction: column;
        position: relative;
        overflow: hidden;
        box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
        border-radius: 2rem;
        & > * {
          overflow-y: auto;
          height: 100%;
        }
      }
    }
    @media only screen and (max-width: 1200px) {
      width: 100vw;
    }
  }

  &__extra {
    display: none;
    @media only screen and (min-width: 500px) {
      display: flex;
      flex: 1;
      margin-left: 2rem;
      overflow-y: auto;
      // padding: 0 2rem;
      // background: red;
      // background: rgba(0, 0, 0, 0.1);
    }
  }
}

.progress-bar {
  // position: absolute;
  top: 0.5rem;
  left: 0;
  padding: 0 0.5rem;

  @media only screen and (min-width: 500px) {
    top: 0;
    position: relative;
  }
}

.info-toggler {
  @media only screen and (min-width: 500px) {
    display: none;
  }
}

.grid {
  display: grid;
  height: 100vh;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: repeat(8, 1fr) 0.5fr;

  header {
    background: red;
    grid-column: 1/-1;
  }
  nav {
    background: yellow;
    grid-column: 1 / span 2;
    grid-row: 2/-1;
  }
  main {
    margin: 1rem;
    // padding: 0.5rem;
    // border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 1rem;
    grid-column: 3/-1;
    grid-row: 2/-2;
    height: 100%;
    overflow: hidden;
  }

  footer {
    text-align: center;
    background: #f6f6f6;
    grid-column: 3/-1;
    grid-row: 9/-1;
  }

  & .dashboard {
    display: grid;
    gap: 1rem;
    height: 35%;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(2, 1fr);

    &__item {
      border: 1px solid rgba(0, 0, 0, 0.3);
      border-radius: 0.5rem;
    }

    &__item--large {
      grid-column: span 2;
      grid-row: span 2;
    }
  }

  .table {
    table {
      overflow-y: scroll;
    }
  }
}

.error-msg {
  box-shadow: 0px 0px 5px 1px red !important;
}

.scrollDisabled {
  position: fixed;
  margin-top: 0; // override by JS to use acc to curr $(window).scrollTop()
  width: 100%;
}

span.document-uploader > div.ant-upload.ant-upload-select.ant-upload-select-picture-card {
  background: #f2f6ff;
  border-radius: 5px;
  border: 2px dashed #a6abb4;
}

.react-html5-camera-photo-fullscreen > video {
  object-fit: cover;
  object-position: center;
}

.flex-column-gap-1 {
  display: flex;
  flex-direction: column;
  > *:not(:last-child) {
    margin-bottom: 1rem;
  }
}

#container-circles {
  bottom: 130px;
  display: none;
}

.term-page {
  > canvas {
    width: 100% !important;
    height: auto !important;
  }
}

.term-text {
  font-size: clamp(1rem, 3vw, 1.2rem);

  > * {
    display: block;
    margin-bottom: 1rem;
  }
  p,
  span {
    display: inline-block;
    text-indent: 2em;
  }
  .indent {
    text-indent: 4em;
  }
  .indent-2 {
    text-indent: 6em;
  }
}

.sigCanvas {
  width: 100%;
  height: 100%;
}

.regis-app-content {
  height: 100%;

  .regis-step-wrapper {
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

@media only screen and (min-width: 600px) {
  // #scroll-to-bottom-btn {
  // }

  .text-fade {
    width: clamp(400px, 40vw, 450px) !important;
    bottom: 1.5rem !important;
    border-radius: 0 0 2rem 2rem !important;
  }

  .regis-app-wrapper {
    background: ~"url('../img/loginbg.jpg')";
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .regis-app-content {
      align-self: center;
      border-radius: 2rem;
      box-shadow: 0 2rem 4rem rgba(0, 0, 0, 0.5);
      width: clamp(400px, 40vw, 450px);
      min-height: unset;
      height: calc(100vh - 8rem);
      margin: unset;
      padding-bottom: 1rem;
    }

    .regis-step-wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
      // margin-right: -17px;
      // padding-right: 20px;
    }

    .regis-app-button {
      width: calc(clamp(400px, 40vw, 450px) - 2rem);
    }
  }

  .regis-app-flex {
    display: flex;
    justify-content: center;
  }

  .regis-app-extra {
    display: flex !important;
    flex-basis: 30%;
    margin-left: 2rem;
    background: red;
    justify-content: center;
    align-items: center;
    object-fit: cover;
    // font-size: 40px;
  }
}

.pad-content {
  padding: 1rem;
}

.words-content {
  font-size: 0.8rem;
  // font-family: 'Browallia New';
  text-align: justify;

  h1 {
    font-size: 1.25rem;
  }

  p {
    margin-block-start: 0.2rem;
    margin-block-end: 0.2rem;
  }

  .indent {
    text-indent: 2rem;
  }

  .bold {
    font-weight: 600;
  }

  .topic {
    margin-block-start: 1.2em;
  }

  .text-center {
    text-align: center;
  }

  .text-underline {
    text-decoration: underline;
  }

  ol,
  ul {
    margin-block-start: 0.2em;
    margin-block-end: 0.2em;
    padding-inline-start: 3rem;
  }
  li::marker {
    content: attr(prefix) ' ';
  }
}

.shadow {
  box-shadow: 0px 3px 6px #00000029;
}

.border-radius {
  border-radius: 10px;
  &__2 {
    border-radius: 15px;
  }
}

.pointer {
  cursor: pointer;
}

.color-primary {
  color: @primary-color;
}

.text-center {
  text-align: center;
}

.w-100 {
  width: 100%;
}
