.step {
  transition: transform 0.2s ease;
}
.swipeIn {
  transform: translateX(100%);
}
.swipeOut {
  transform: translateX(-100%);
}

.animatedIn {
  animation: swipeIn 0.2s ease;
}
.animatedOut {
  animation: swipeOut 0.2s ease;
}

@keyframes swipeIn {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}
@keyframes swipeOut {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes flyFromBottom {
  0% {
    opacity: 0;
    transform: translate(-50%, 0);
  }

  100% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

@keyframes scaleAppear {
  0% {
    transform: scale(0);
  }
  90% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes scaleAppear2 {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }
  90% {
    transform: translate(-50%, -50%) scale(1.1);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes scale {
  0% {
    transform: scale(0);
  }
  90% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1.4);
  }
}

@keyframes arrowPointing {
  0% {
    transform: translate(0) rotate(45deg);
  }
  100% {
    transform: translate(-0.5rem, -0.5rem) rotate(45deg);
  }
}

.spinning-infi {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
